import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import { colors } from "utils/variables"
import breakpoints from "utils/breakpoints/"

// Components
import Container from "components/container"

// Icons
import IconCheck from "assets/icons/icon-check.svg"
import IconDescription from "assets/icons/icon-description.inline.svg"

const StyledDescription = styled.section`
  padding: 64px 0px 64px 0px;
  background-color: ${colors.silver__pale};

  ${breakpoints.medium`
  padding: 187px 0px 176px 0px;
  `}

  .icon-description {
    position: relative;
    bottom: 125px;
    left: -55px;
    margin-bottom: -125px;
  }

  .card-container {
    width: 100%;
    background-color: ${colors.white};
    border-radius: 20px;
    padding: 24px;
    margin-top: 32px;

    ${breakpoints.medium`
    padding: 41px 56px 34px 56px;
    margin-top: 0px;
    `}
  }

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 26px;

    &::before {
      content: url("${IconCheck}");
      width: 24px;
      height: 24px;
      position: relative;
      display: inline-block;
      margin-right: 16px;
      align-self: center;
    }

    &:last-child {
      margin-bottom: 0;
    }

    p {
      display: inline-block;
      margin-left: 16px;
      font-weight: 600;
    }
  }
`

const Description = () => (
  <StyledDescription>
    <Container>
      <div className="row justify-content-md-between align-items-center align-items-md-start">
        <div className="col-12 col-md-6">
          <div className="text-section">
            <h2 className="h2 maxi">Bias Mitigation</h2>
            <p className="text--large mt-3">
              Biased hiring hurts both candidates and organizations by keeping
              qualified candidates from positions where they would thrive.
              That's why our platform uses a machine learning technique known as
              adversarial fairness—to avoid making biased predictions that
              amplify social bias.
            </p>
          </div>
        </div>
        <div className="col-12 col-md-5">
          <div className="card-container">
            <h3 className="h3 mb-4">
              Arena’s Bias Mitigation solution helps employers to:
            </h3>
            <ul>
              <li className="item d-flex align-items-center">
                <p>Reduce bias in the hiring process</p>
              </li>
              <li className="item d-flex align-items-center">
                <p>Increase equity and diversity</p>
              </li>
              <li className="item d-flex align-items-center">
                <p>Improve workforce stability</p>
              </li>
            </ul>
          </div>
          <IconDescription className="icon-description d-none d-md-block" />
        </div>
      </div>
    </Container>
  </StyledDescription>
)

export default Description
