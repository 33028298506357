import React from "react"

// Layout
import Layout from "layouts/layout-primary"

// Components
import SEO from "components/seo/"

// Sections
import Features from "sections/bias-mitigation/features"
import Hero from "sections/bias-mitigation/hero"
import Description from "sections/bias-mitigation/description"
import CognitiveShorcuts from "sections/bias-mitigation/cognitive-shortcuts"

const BiasMitigation = () => (
  <Layout showNewsletter={false}>
    <SEO title="Bias Mitigation | Arena Analytics" />
    <Hero />
    <Description />
    <CognitiveShorcuts />
    <Features />
  </Layout>
)

export default BiasMitigation
