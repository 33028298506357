import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import { colors } from "utils/variables"
import breakpoints from "utils/breakpoints/"

// Components
import Container from "components/container"

const StyledCognitiveShortcuts = styled.div`
  padding: 64px 0px;
  background-color: ${colors.navy__extra_dark};

  ${breakpoints.medium`
  padding: 147px 0px;
  `}
`

const CognitiveShorcuts = () => (
  <StyledCognitiveShortcuts>
    <Container>
      <div className="row justify-content-md-between align-items-md-center">
        <div className="col-12 col-md-4">
          <h2 className="h2 maxi color--silver__extra_pale text-center text-md-start">
            Moving Past
            <br />
            <span className="color--turquoise__primary">
              Cognitive Shortcuts
            </span>
          </h2>
        </div>
        <div className="col-12 col-md-7 mt-3 mt-md-0">
          <p className="color--silver__extra_pale text--large text-center text-md-start">
            Everyone has developed cognitive shortcuts as a necessary element of
            navigating everyday life. But these same shortcuts can prove
            unproductive at best and harmful at worst when making hiring
            decisions that require nuance and outside-the-box thinking. Arena
            helps employers move past unconscious bias and discover a new, more
            objective and expansive lens through which to view talent.{" "}
          </p>
        </div>
      </div>
    </Container>
  </StyledCognitiveShortcuts>
)

export default CognitiveShorcuts
