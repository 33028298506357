import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import { colors } from "utils/variables"
import breakpoints from "utils/breakpoints"

// Components
import Container from "components/container/"
import Lottie from "components/lottie"

// Animation
import Bias from "assets/animations/how-it-works/bias-mitigation.json"
import DataModel from "assets/animations/how-it-works/data-model.json"
import AdversarialFairnes from "assets/animations/how-it-works/adversarial-fairnes.json"

const StyledFeatures = styled.section`
  background-color: ${colors.silver__extra_pale};
  padding: 60px 0;

  ${breakpoints.medium`
    padding: 120px 0;
    p {
      max-width: 353px;
      width: 100%;
    }
  `}
`

const Features = () => {
  const info = [
    {
      icon: <Lottie animation={DataModel} />,
      title: "Data Models",
      feature:
        "Our predictor model produces retention predictions for candidates, while our discriminator model analyzes the same data to identify demographic classification data.",
    },
    {
      icon: <Lottie animation={AdversarialFairnes} />,
      title: "Adversarial Fairness",
      feature:
        "By pitting these models against one another, Adversarial Fairness removes biased data points while delivering accurate retention predictions.",
    },
    {
      icon: <Lottie animation={Bias} />,
      title: "Unbiased Results",
      feature:
        "The result? Unbiased predictions with recommended hires surpassing the EEOC’s compliance threshold to ensure that your hiring pool mirrors your applicant pool.",
    },
  ]
  return (
    <StyledFeatures>
      <Container>
        <div className="row p-0">
          {info.map((data) => (
            <div className="col-12 col-md-4 mb-5 mb-lg-0" key={data.feature}>
              <div className="mb-1">{data.icon}</div>
              <h3 className="h3 mt-4">{data.title}</h3>
              <p className="text--large">{data.feature}</p>
            </div>
          ))}
        </div>
      </Container>
    </StyledFeatures>
  )
}

export default Features
